import React, { useState } from "react";
import JobSearch from "./Views/JobSearch";
import JobPostingForm from "./Views/JobPostForm";
import JobPostingView from "./Views/JobPostingView";

const App = () => {
  const [jobData, setJobData] = useState(null);

  const handleFormSubmit = (data) => {
    setJobData(data);
  };

  return (
    <div>
      <JobSearch />
      <div className="bg-[#FAF0E6] min-h-screen py-8">
        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6 mb-8">
          <h1 className="text-3xl font-bold text-[#8B4513] mb-4">
            Job Posting Form
          </h1>
          <JobPostingForm onSubmit={handleFormSubmit} />
        </div>
        {jobData && <JobPostingView jobData={jobData} />}
      </div>
    </div>
  );
};

export default App;

import React, { useState } from "react";
import { ChevronDown, MapPin, Star } from "lucide-react";

const JobPostingForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    description: "",
    designation: "",
    workTime: "",
    location: "",
    experienceRequired: "",
    date: new Date().toLocaleDateString("en-GB"),
    responsibilities: "",
    qualifications: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      responsibilities: formData.responsibilities.split("\n"),
      qualifications: formData.qualifications.split("\n"),
      offers: [
        { name: "Flexible working time", rating: 4 },
        { name: "Paid leaves", rating: 4 },
      ],
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">
          Description
        </h2>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded-md"
          rows="4"
        />
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">
          Designation
        </h2>
        <div className="relative">
          <select
            name="designation"
            value={formData.designation}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md appearance-none"
          >
            <option value="">Select Designation</option>
            <option value="Java Developer">Java Developer</option>
            <option value="Frontend Developer">Frontend Developer</option>
            <option value="Full Stack Developer">Full Stack Developer</option>
          </select>
          <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">
          Work Time
        </h2>
        <input
          type="text"
          name="workTime"
          value={formData.workTime}
          onChange={handleInputChange}
          placeholder="Enter time"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">Location</h2>
        <input
          type="text"
          name="location"
          value={formData.location}
          onChange={handleInputChange}
          placeholder="Enter location"
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">
          Experience Required
        </h2>
        <div className="relative">
          <select
            name="experienceRequired"
            value={formData.experienceRequired}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md appearance-none"
          >
            <option value="">Select Experience</option>
            <option value="0-1 years">0-1 years</option>
            <option value="1-3 years">1-3 years</option>
            <option value="3-5 years">3-5 years</option>
            <option value="5+ years">5+ years</option>
          </select>
          <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">
          Responsibilities
        </h2>
        <textarea
          name="responsibilities"
          value={formData.responsibilities}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded-md"
          rows="4"
          placeholder="Enter responsibilities (one per line)"
        />
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-[#8B4513] mb-2">
          Qualifications
        </h2>
        <textarea
          name="qualifications"
          value={formData.qualifications}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded-md"
          rows="4"
          placeholder="Enter qualifications (one per line)"
        />
      </div>

      <button
        type="submit"
        className="w-full bg-[#CD853F] text-white py-2 px-4 rounded-md hover:bg-[#8B4513] transition duration-300"
      >
        Add
      </button>
    </form>
  );
};
export default JobPostingForm;

import React from "react";
import { ChevronDown, MapPin, Star } from "lucide-react";

const JobPostingView = ({ jobData }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 max-w-3xl mx-auto">
      <h1 className="text-4xl font-bold text-[#8B4513] mb-4">Job view</h1>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold text-[#8B4513]">
          {jobData.designation}
        </h2>
        <div className="flex items-center text-gray-600 mb-1">
          <MapPin size={16} className="mr-2" />
          <span>{jobData.location}</span>
        </div>
        <div className="text-gray-600">
          work time: {jobData.workTime} | Date: {jobData.date}
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold text-[#8B4513] mb-2">
          Description
        </h3>
        <p className="text-gray-700">{jobData.description}</p>
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold text-[#8B4513] mb-2">
          Responsibilities
        </h3>
        <ul className="list-disc pl-5 text-gray-700">
          {jobData.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold text-[#8B4513] mb-2">
          Experience required
        </h3>
        <p className="text-gray-700">{jobData.experienceRequired}</p>
      </div>

      <div className="mb-4">
        <h3 className="text-xl font-semibold text-[#8B4513] mb-2">
          Qualifications & Interpersonal skills required
        </h3>
        <ul className="list-disc pl-5 text-gray-700">
          {jobData.qualifications.map((qualification, index) => (
            <li key={index}>{qualification}</li>
          ))}
        </ul>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold text-[#8B4513] mb-2">
          what we offer
        </h3>
        {jobData.offers.map((offer, index) => (
          <div key={index} className="flex items-center mb-1">
            <span className="text-gray-700 mr-2">{offer.name}</span>
            <div className="flex">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  size={16}
                  fill={i < offer.rating ? "#8B4513" : "none"}
                  stroke="#8B4513"
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center">
        <img
          src="/api/placeholder/100/50"
          alt="Company Logo"
          className="h-12"
        />
        <button className="bg-[#CD853F] text-white py-2 px-6 rounded-md hover:bg-[#8B4513] transition duration-300">
          Apply Now
        </button>
      </div>
    </div>
  );
};
export default JobPostingView;

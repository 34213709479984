import React from "react";
import "./style.css";
import { Search } from "lucide-react";
import Image from "../Assets/young-handsome-business-man-with-laptop-office (1).jpg";

const JobSearch = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/2 flex flex-col justify-center items-start p-12">
        <h1 className=" job-search-heading  text-5xl font-bold text-brown-400 mb-8">
          Find your job
        </h1>
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="job title"
            className="w-full py-2 px-4 pr-12 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brown-500"
          />
          <button className="absolute right-0 top-0 h-full px-4 bg-brown-500 text-white rounded-r-md hover:bg-brown-600 transition duration-200">
            <Search color="black" size={20} />
          </button>
        </div>
      </div>
      <img className="w-1/2 bg-cover bg-center" src={Image} />
      {/* This is where the image would go. We're using a placeholder for demonstration. */}
    </div>
  );
};

export default JobSearch;
